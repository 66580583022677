function UpdatePubsFieldsFromQueryString(isDesktop) {
    var ddlVal = getParamValueFrmKey('sortby');
    if (ddlVal) {
        $("#publicationSort").val(ddlVal);
        var userText = $("#publicationSort option:selected").text();
        $('#oldSelectedvalPub').val($("#publicationSort option:selected").val());
        $(".sortSel .main #ulpublicationSort .stylish-select-left .stylish-select-right").text(userText);
    }
    else {
        var ddlName = 'publicationSort';
        $(".sortSel #" + ddlName).prop('selectedIndex', 0);
        var userText = $('#' + ddlName).val();
        $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
        $('#oldSelectedvalPub').val($('#' + ddlName + '').val());
        $("#ul" + ddlName + " ul.listing li").removeClass("selected");
        $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
    }

    var openFewerOptions = $.urlParam('openfeweroptions');
    if (openFewerOptions != null && openFewerOptions == "true") {
        if (!$(".fewerOption a").hasClass("expand")) {
            $(".fewerOption a").parent().next(".hiddenFewerForm").slideDown();
            $(".fewerOption a").addClass("expand");

            var hideText = $(".fewerOption a").attr("data-hide");
            $(".fewerOption a").text(hideText);
        }
    }
    var ddlValpagesize = getParamValueFrmKey('pagesize');
    if (ddlValpagesize) {
        if (isDesktop) {
            $("#ddlPageSize_pub").val(ddlValpagesize);
            var userTextPagesize = $("#ddlPageSize_pub option:selected").text();
            $('#oldSelectedPageSize_pub').val($("#ddlPageSize_pub option:selected").val());
            $(".sortSel .main #ulddlPageSize_pub .stylish-select-left .stylish-select-right").text(userTextPagesize);
        }
        else {
            $("#ddlPageSize_pubMob").val(ddlValpagesize);
            var userTextPagesize = $("#ddlPageSize_pubMob option:selected").text();
            $('#oldSelectedPageSize_pub').val($("#ddlPageSize_pubMob option:selected").val());
            $(".sortSel .main #ulddlPageSize_pubMob .stylish-select-left .stylish-select-right").text(userTextPagesize);
        }
    }
    else {
        if (isDesktop) {
            var ddlName = 'ddlPageSize_pub';
            $(".sortSel #" + ddlName).prop('selectedIndex', 0);
            var userText = $('#' + ddlName).val();
            $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
            $('#oldSelectedPageSize_pub').val($('#' + ddlName + '').val());
            $("#ul" + ddlName + " ul.listing li").removeClass("selected");
            $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
        }
        else {
            var ddlName = 'ddlPageSize_pubMob';
            $(".sortSel #" + ddlName).prop('selectedIndex', 0);
            var userText = $('#' + ddlName).val();
            $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
            $('#oldSelectedPageSize_pub').val($('#' + ddlName + '').val());
            $("#ul" + ddlName + " ul.listing li").removeClass("selected");
            $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
        }
    }

}

(function ($) {
    var plugin_Pub = this;
    plugin_Pub.settings = {};

    plugin_Pub.settings.groupName = "";
    plugin_Pub.settings.listUrl = "";
    plugin_Pub.settings.listType = "catcompletepeople1";


    $.autoSuggest_pub = function (element, options) {
        /* defaults */
        var defaults = {
            itemId: "",
            listUrl: "/api/custom/eventlisting/getautosuggestresult",
            listType: "catcompletepeople"

        };
        var $element = $(element), element = element;
        var plugin_Pub = this;
        plugin_Pub.settings = {}

        /* init function */
        plugin_Pub.init = function () {

            // clearAutoSuggestBox();
            plugin_Pub.settings = $.extend({}, defaults, options);


            if (plugin_Pub.settings.listType == "catcompleteGroup") {
                if ($element.catcompleteGroup) {
                    $element.catcompleteGroup({
                        source: plugin_Pub.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);
                            $(this).attr("filter-IndexId", ui.item.FilterID);
                            $(this).attr("filter-IndexName", ui.item.FilterIndexName)
                            $(this).attr("filter-IndexValue", ui.item.FilterName);
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }
            if (plugin_Pub.settings.listType == "catcompletepeople1") {
                if ($element.catcompletepeople1) {
                    $element.catcompletepeople1({
                        source: function (request, response) {
                            $.getJSON(plugin_Pub.settings.listUrl, { currentTime: $.now(), term: request.term },
                                response);
                        },
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.DisplayName);
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }
            if (plugin_Pub.settings.listType == "catcompletepeopleid") {
                if ($element.catcompletepeopleid) {
                    $element.catcompletepeopleid({
                        source: plugin_Pub.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.DisplayName);
                            $(this).attr("filter-IndexId", ui.item.PersonURL);
                            $(this).attr("filter-IndexValue", ui.item.DisplayName);
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }
            if (plugin_Pub.settings.listType == "catcompletepublication") {
                if ($element.catcompletepublication) {
                    $element.catcompletepublication({
                        source: function (request, response) {
                            $.getJSON(plugin_Pub.settings.listUrl, { currentTime: $.now(), term: request.term },
                                response);
                        },
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(decodeURIComponent(ui.item.FilterName));

                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }

            if (plugin_Pub.settings.listType == "catcompletepress") {
                if ($element.catcompletepress) {
                    $element.catcompletepress({
                        source: function (request, response) {
                            $.getJSON(plugin_Pub.settings.listUrl, { currentTime: $.now(), term: request.term },
                                response);
                        },
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);

                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }


        }
        plugin_Pub.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.autoSuggest_pub = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('autoSuggest')) {
                var plugin_Pub = new $.autoSuggest_pub(this, options);
                $(this).data('autoSuggest', plugin_Pub);
            }
        });
    }


    $(document).ready(function () {
        var autoSuggest_pub = $('#people-searchnews').autoSuggest_pub({
            listUrl: "/api/custom/biolisting/getautosuggestresult",
            listType: "catcompletepeopleid"
        });
    });

    $(document).ready(function () {
        $('.publicSelectAll .checkbox input[type="checkbox"]').click(function () {
            if ($(this).prop("checked")) {
                $('.publicationCheckOpt .checkbox input[type="checkbox"]').removeAttr('checked');
                $('.publicationCheckOpt .checkbox, .publicSelectAll .checkbox').css("background-position", "center 0");
                $(this).removeAttr('checked');
            } else {
                //$(".publicationCheckOpt input:checkbox").prop('checked', $(this).prop("checked"));
                $('.publicationCheckOpt .checkbox input[type="checkbox"]').prop("checked", true);
                $('.publicationCheckOpt .checkbox, .publicSelectAll .checkbox').css("background-position", "center -40px");
                $(this).prop("checked", true);
            }
        });


        $('.publicationCheckOpt .checkbox input[type="checkbox"]').click(function () {
            if ($(this).prop("checked")) {
                $(this).removeAttr('checked');
                $(this).parent().css("background-position", "center 0px");
                $('.publicSelectAll .checkbox input[type="checkbox"]').removeAttr('checked');
                $('.publicSelectAll .checkbox').css("background-position", "center 0px");
            } else {
                $(this).prop("checked", true);
                $(this).parent().css("background-position", "center -40px");

                var isAllChecked = true;
                $('.checkbox1').each(function () {
                    if (($(this).attr("checked") != 'checked')) {
                        isAllChecked = false;
                    }
                });

                if (isAllChecked) {
                    //$(".publicSelectAll input:checkbox").prop('checked', $(this).prop("checked"));
                    $('.publicSelectAll .checkbox input[type="checkbox"]').prop("checked", true);
                    $('.publicSelectAll .checkbox').css("background-position", "center -40px");
                }
            }
        });
    });


}(jQuery));
function ClearPubfilterAttr(jDivObj) {
    $(jDivObj).attr('filter-indexid', '');
    $(jDivObj).attr('filter-indexname', '');
    $(jDivObj).attr('filter-indexvalue', '');
}

function GetPubPushString(jDivObj, filterName) {
    console.log(jDivObj, filterName)
    var filterID = $(jDivObj).attr('filter-indexid');
    var ReturnVal = '';
    var IsClear = true;
    if ($(jDivObj).val() && $(jDivObj).attr("filter-indexvalue")) {
        if ($(jDivObj).val().toLowerCase() == $(jDivObj).attr("filter-indexvalue").toLowerCase()) {
            if (filterID != null && filterID != undefined && filterName != null && filterName != undefined && filterID != '' && filterName != '') {
                ReturnVal = filterName + "=" + filterID;
                IsClear = false;
            }
        }
    }
    if (IsClear) {
        ClearPubfilterAttr(jDivObj);
    }
    //myData[$("#sector-search").attr("filter-indexname")] = $("#sector-search").attr("filter-indexid");

    return ReturnVal;
}

function SetSampleData() {
    //$("#sector-search").val("International Arbitration");
    //$("#sector-search").data("value", '14f3e421b07e408d8433b2187afb8819');
    //$("#sector-search").data("category", 'param_practices_sm');

    //$("#sector-search").val("International Arbitration");
    //$("#sector-search").data("value", 'ebe6e729d18343eb8f28ab99797acab9');
    //$("#sector-search").data("category", 'publicationType');
    // $("#pub-searchText").val("law");
    $("#people-search").val('');


}
function GetFilterResponse(additionalFilter) {
    $('#divProcessingPublication').show();
    $('.pub-search-area').hide();
    $('.no-result-found').hide();
    if ($(window).width() < 500) {
        $('html, body').animate({
            'scrollTop': $('.pubResultScrollDiv').offset().top - 160
        });
    } else {
        $('html, body').animate({
            'scrollTop': $('.pubResultScrollDiv').offset().top - 250
        });

    }
    //http://starterkit/Publication?publicationType=ebe6e729d18343eb8f28ab99797acab9&searchstring=lawaakashla
    // SetSampleData();
    var myData = {};
    if ($("#pub-searchText").val() != '' && $("#pub-searchText").val().toLowerCase() != $("#pub-searchText").data('defaultvalue').toLowerCase()) {
        myData["searchstring"] = encodeURIComponent($("#pub-searchText").val());
        //myData["searchstring"] = $("#pub-searchText").val();
    }

    var sectordata = GetPubPushString("#sector-search", $("#sector-search").attr("filter-indexname"));
    if (sectordata) {
        myData[$("#sector-search").attr("filter-indexname")] = $("#sector-search").attr("filter-indexid");
    }

    if ($("#people-search").data("category")) {
        if ($("#people-search").val() != '' && $("#people-search").val().toLowerCase() != $("#people-search").data('defaultvalue').toLowerCase()) {
            myData[$("#people-search").data("category")] = $("#people-search").val();
        }
    }

    if ($("#start_date-search").val() != '' && $("#start_date-search").val().toLowerCase() != $("#start_date-search").data('defaultvalue').toLowerCase()) {
        myData[$("#start_date-search").data("category")] = $("#start_date-search").val();
    }
    if ($("#end_date-search").val() != '' && $("#end_date-search").val().toLowerCase() != $("#end_date-search").data('defaultvalue').toLowerCase()) {
        myData[$("#end_date-search").data("category")] = $("#end_date-search").val();
    }

    if (additionalFilter) {
        $.each(additionalFilter, function () {
            myData[this[0]] = this[1];
        });
    }

    if (!($("#allPubType").prop("checked"))) {
        var checkVal = '';
        $('.checkbox1').each(function () {
            if ($(this).prop("checked")) {
                checkVal += $(this).val() + ",";
            }
        });
        if (checkVal.length > 1) {
            checkVal = checkVal.substring(0, checkVal.length - 1);
        } else {
            checkVal = 'na';
        }
        myData[$("#allPubType").data("category")] = checkVal;
    }

    if ($('.pubYearDropdown select').val()) {
        var selectedYearVal = $('.pubYearDropdown select').val();
        if (selectedYearVal != "") {
            myData[$('.pubYearDropdown select').find(':selected').data('category')] = selectedYearVal;
        }
    }

    if ($('#publicationSort').val()) {
        var selectedYearVal = $('#publicationSort').val();
        if (selectedYearVal != "") {
            myData['sortby'] = selectedYearVal;
        }
    }


    if ($('#oldSelectedPageSize_pub').val()) {
        var selectedVal = $('#oldSelectedPageSize_pub').val();
        if (selectedVal != "") {
            myData['pagesize'] = selectedVal;
        }
    }

    var practiceValue = $('#sector-search').val();
    //if (sectordata==false && practiceValue != "" && practiceValue.toLowerCase() != $('#sector-search').data('defaultvalue').toLowerCase()) {
    if (practiceValue != "" && practiceValue.toLowerCase() != $('#sector-search').data('defaultvalue').toLowerCase()) {
        var selectedPracticeVal = $('#sector-search').val();
        if (selectedPracticeVal != "") {
            myData['practice_name'] = encodeURIComponent(selectedPracticeVal);
        }
    }
    if ($('#allPubType').prop("checked")) {
        myData['allpubcheck'] = "true";
    }
    else {
        myData['allpubcheck'] = "false";
    }
    PublicationAjaxCall(myData);

    return false;
}

function GetPubRedirectURL(additionalFilter) {

    var myData = [];

    if ($(".fewerOption a").hasClass("expand")) {
        myData.push("openfeweroptions=true");
    }
    if ($('#publicationSort').val()) {
        var selectedYearVal = $('#publicationSort').val();
        if (selectedYearVal != "") {
            myData.push('sortby=' + selectedYearVal);
        }
    }
    if ($('#oldSelectedPageSize_pub').val()) {
        var selectedVal = $('#oldSelectedPageSize_pub').val();
        if (selectedVal != "") {
            myData.push('pagesize=' + selectedVal);
        }
    }
    if (additionalFilter) {
        $.each(additionalFilter, function () {
            myData.push(this[0] + "=" + this[1]);
        });
    }

    if ($("#start_date-search").val() != '' && $("#start_date-search").val().toLowerCase() != $("#start_date-search").data('defaultvalue').toLowerCase()) {
        myData.push($("#start_date-search").data("category") + "=" + encodeURIComponent($("#start_date-search").val()));
    }
    else { myData.push($("#start_date-search").data("category") + "=" + encodeURIComponent($("#start_date-search").data('defaultvalue'))); }

    if ($("#end_date-search").val() != '' && $("#end_date-search").val().toLowerCase() != $("#end_date-search").data('defaultvalue').toLowerCase()) {
        myData.push($("#end_date-search").data("category") + "=" + encodeURIComponent($("#end_date-search").val()));
    }
    else { myData.push($("#end_date-search").data("category") + "=" + encodeURIComponent($("#end_date-search").data('defaultvalue'))); }

    if ($("#people-search").data("category")) {
        if ($("#people-search").val() != '' && $("#people-search").val().toLowerCase() != $("#people-search").data('defaultvalue').toLowerCase()) {
            myData.push($("#people-search").data("category") + "=" + encodeURIComponent($("#people-search").val()));
        }
    }

    var practicetxtBox = $("#sector-search");
    var sectordata = GetPubPushString("#sector-search", practicetxtBox.attr("filter-indexname"));
    if (sectordata) {
        myData.push("practice_name=" + encodeURIComponent(practicetxtBox.val()));
        myData.push("practice_indexid=" + practicetxtBox.attr("filter-indexid"));
        myData.push("practice_indexname=" + practicetxtBox.attr("filter-indexname"));
        myData.push("practice_indexvalue=" + encodeURIComponent(practicetxtBox.attr("filter-indexvalue")));
        // myData[$("#presssector-search").attr("filter-indexname")] = $("#presssector-search").attr("filter-indexid");
    }
    else {
        if (practicetxtBox.val().toLowerCase() != practicetxtBox.data('defaultvalue').toLowerCase()) {
            myData.push("practice_name=" + encodeURIComponent(practicetxtBox.val()));
        }
    }

    if (!($("#allPubType").prop("checked"))) {
        var checkVal = '';
        $('.checkbox1').each(function () {
            if ($(this).prop("checked")) {
                checkVal += $(this).val() + ",";
            }
        });
        if (checkVal.length > 1) {
            checkVal = checkVal.substring(0, checkVal.length - 1);
        } else {
            checkVal = 'na';
        }
        myData.push($("#allPubType").data("category") + "=" + encodeURIComponent(checkVal));
    }
    if ($('#allPubType').prop("checked")) {
        myData.push("allpubcheck=true");
    }
    else {
        myData.push("allpubcheck=false");
    }

    if ($("#pub-searchText").val() != '' && $("#pub-searchText").val().toLowerCase() != $("#pub-searchText").data('defaultvalue').toLowerCase()) {
        myData.push("searchstring" + "=" + encodeURIComponent($("#pub-searchText").val()));
    }

    var currentHref = window.location.href.split('?')[0];
    window.location = currentHref + "?" + myData.join("&");
    return false;
}

function PubsSortingChange() {
    var oldSelectedval = $('#oldSelectedvalPub').val();
    if (oldSelectedval != $('#publicationSort').val()) {
        $('#oldSelectedvalPub').val($('#publicationSort').val());
        $('#divProcessingPublication').show();
        $('.pub-search-area').hide();
        $('.no-result-found').hide();
        GetPubRedirectURL();
    }
}

function PubPageSizeChange() {
    var oldSelectedval = $('#oldSelectedPageSize_pub').val();
    if (oldSelectedval != $('#ddlPageSize_pub').val()) {
        $('#oldSelectedPageSize_pub').val($('#ddlPageSize_pub').val());
        $('#divProcessingPublication').show();
        $('.pub-search-area').hide();
        $('.no-result-found').hide();
        GetPubRedirectURL();
    }
}

function PubPageSizeChangeMob() {
    var oldSelectedval = $('#oldSelectedPageSize_pub').val();
    if (oldSelectedval != $('#ddlPageSize_pubMob').val()) {
        $('#oldSelectedPageSize_pub').val($('#ddlPageSize_pubMob').val());
        $('#divProcessingPublication').show();
        $('.pub-search-area').hide();
        $('.no-result-found').hide();
        GetPubRedirectURL();
    }
}

function PublicationAjaxCall(myData) {
    //var alertText = $('#hdnViewAllAlert').html();
    //var alertTimer = parseInt($('#hdnViewAllAlertTimer').val(), 10);
    //if (isNaN(alertTimer))
    //{ alertTimer = 10; }
    //var timer = setTimeout(function () { alert(alertText); }, alertTimer * 1000);
    //var closetimer = true;
    //if ($('#hdnIsViewAllClicked')) {
    //    if ($('#hdnIsViewAllClicked').val() == 'true')
    //    { closetimer = false; }
    //}
    //if (closetimer)
    //{ clearTimeout(timer); }
    $('.pub-search-area').hide();
    $('.no-result-found').hide();
    $.ajax({
        dataType: "html",
        type: 'Get',
        url: '/publicationresult',
        data: myData,
        success: function (data) {
            // clearTimeout(timer);
            //$('.pub-search-area').show();
            if (data && data.length > 10) {
                $('#pubsResultData').html('');
                $('#pubsResultData').html(data);
                //$('.pub-search-area').show();
                $('.pub-search-area').show();

                // $('#publicationSort').edyCustomSelectBox();

            }
            else {
                $('.pub-search-area').hide();
                $('#pubsResultData').html('');
                $('.no-result-found').show();
            }
            $('#divProcessingPublication').hide();
            if ($(window).width() < 500) {
                $('html, body').animate({
                    'scrollTop': $('.pubResultScrollDiv').offset().top - 170
                });
            } else {
                $('html, body').animate({
                    'scrollTop': $('.pubResultScrollDiv').offset().top - 200
                });

            }
            return false;
        },
        error: function (data) {
            // clearTimeout(timer);
            $('#divProcessingPublication').hide();
            if ($(window).width() < 500) {
                $('html, body').animate({
                    'scrollTop': $('.pubResultScrollDiv').offset().top - 170
                });
            } else {
                $('html, body').animate({
                    'scrollTop': $('.pubResultScrollDiv').offset().top - 200
                });

            }
            //alert('error');
            return false;
        }
    });
    return false;
}
function onBlurr(thisObj) {
    if ($(thisObj).val() == '') {
        thisObj.style.color = "#7f7f7f";
        $(thisObj).val($(thisObj).data('defaultvalue'));
    }

}
function onFocuss(thisObj) {
    thisObj.style.color = "#000";
    if ($(thisObj).val().toLowerCase() == $(thisObj).data('defaultvalue').toLowerCase()) {
        $(thisObj).val('');
    }
}
function ClearFields() {
    $('#pub-searchText').val($('#pub-searchText').data('defaultvalue'));
    $('#sector-search').val($('#sector-search').data('defaultvalue'));
    $("#sector-search").removeAttr('filter-indexid');
    $("#sector-search").removeAttr('filter-indexname');
    $('#people-search').val($('#people-search').data('defaultvalue'));
    //$(".pubYearDropdown select").prop('selectedIndex', 0);

    //var userText = $(".pubYearDropdown .main ul.listing li:first").text();
    //$(".pubYearDropdown .main #ulYear .stylish-select-left .stylish-select-right").text(userText);
    $("#end_date-search").datepicker("option", "minDate", null);
    $("#start_date-search").datepicker("option", "maxDate", null);
    $('#start_date-search').val($('#start_date-search').data('defaultvalue'));
    $('#end_date-search').val($('#end_date-search').data('defaultvalue'));

    allCheckBoxChange(1);
    if ($('#publicationSort')) {
        $('#publicationSort').prop('selectedIndex', 0);
    }
    return false;
}
function allCheckBoxChange(isSelect) {
    if (isSelect) {
        $('.publicationCheckOpt .checkbox input[type="checkbox"]').prop("checked", true);
        $('.publicationCheckOpt .checkbox').css("background-position", "center -40px");
        $('.publicSelectAll .checkbox input[type="checkbox"]').prop("checked", true);
        $('.publicSelectAll .checkbox').css("background-position", "center -40px");
    }
    else {
        $('.publicationCheckOpt .checkbox input[type="checkbox"]').removeAttr("checked");
        $('.publicationCheckOpt .checkbox').css("background-position", "center 0px");
        $('.publicSelectAll .checkbox input[type="checkbox"]').removeAttr("checked");
        $('.publicSelectAll .checkbox').css("background-position", "center 0px");
    }
}

function selectCheckBoxFromDataAttr(attrValue) {
    var isAllChecked = true;
    $('.checkbox1').each(function () {
        if ($(this).val() == attrValue) {
            $(this).prop("checked", true);
            $(this).parent().css("background-position", "center -40px");
        }
        if (($(this).prop("checked"))) {
            isAllChecked = false;
        }
    });
    if (isAllChecked) {
        $('.publicSelectAll .checkbox input[type="checkbox"]').prop("checked", true);
        $('.publicSelectAll .checkbox').css("background-position", "center -40px");
    }
}